﻿// Mobile first media query.
// param "from-breakpoint" must be in pixels
@mixin bi-mq($from-breakpoint) {
  @media screen and (min-width: $from-breakpoint) {
    @content;
  }
}

// Breakpoints for media queries as defined by Prime Ng's grid system
// https://www.primefaces.org/primeng/showcase/#/primeflex/grid
$bp-screen-sm: 576px;
$bp-screen-md: 768px;
$bp-screen-lg: 992px;
$bp-screen-xl: 1200px;
