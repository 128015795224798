@use "functions" as fn;

/*#region ========= Widths and heights*/
$height-main-logo: fn.rem(65);

$max-width-buttons: fn.em(246);
/* #endregion ============*/

/*#region Paddings ========*/
$pt-topMenu-bar: 1rem;
/*#endregion ============*/

/*Border radius used for buttons, expanding panels,content boxes and most other things */
$border-radius-general: fn.em(5);

$dialog-content-wrapper-max-width: fn.rem(400);
