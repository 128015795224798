/*=================== These are NOT to be used in the Subscription Application =======================*/
/*====================================================================================================*/
@use "sass:color";

$color-grey-1: #222222;
$color-grey-2: #484848;
$color-grey-3: #686868;
$color-grey-4: #888888;
$color-grey-5: #a8a8a8;
$color-grey-6: #f5f5f5;

$f24-blue: #00b1eb;
$f24-graphic-color-6: #2a7db2;

$color-valencia: #ef4444; //invalid red
$color-valencia-brighter: #c25959; //brighter invalid red

$color-nero: #222222;
$color-charcoal: #4b4b4b;
$color-empress: #767676;
$color-very-light-grey: #c8c8c8;
$color-gainsboro: #e9e9e9;
$color-white-smoke: #f5f5f5;
$color-black: #000000;
$color-yellow: #fbc02d;
$color-green: #4caf50;
$color-text: $color-grey-2;
$color-delete: $color-valencia;
$color-border: #c8c8c8; // same as Prime Ng borders

$color-white: #ffffff;

$icon-blue: #00a7e7; // color for icons
$color-facebook: #1877f2;
$color-twitter: #1b95e0;

$color-casablanca: #f59e0b; //yellow
$color-brand: $f24-blue; // primary color used on site
$color-brand-brighter: color.adjust($f24-blue, $lightness: 3%);

/*#region Semantic colors*/
$color-notification: $color-casablanca;
$color-valid: $color-green;
$color-invalid: $color-valencia;
$color-delete: $color-valencia;
$color-danger: $color-valencia;
$color-danger-hover: $color-valencia-brighter;
$color-critical: $color-valencia;

$color-text-dark: $color-grey-1;
$color-bg: $color-grey-6;
$color-link: $color-brand;
$color-link-danger: $color-valencia;
$color-link-danger-hover: $color-valencia-brighter;
$color-link-hover: $color-brand-brighter;
$color-disabled: $color-gainsboro; // for disabled inputs
$color-excel: #1a7343; // for excel button or other places where this green color should be used
$background-color-overlay: #656565;
$color-button-no-highlight: #dadada;

/*#endregion*/

//#region Gradients
$gradient-green: linear-gradient(#388e3c, #4caf50);
$gradient-yellow: linear-gradient(#fbc02d, #ffeb3b);
$gradient-red: linear-gradient($color-valencia, $color-valencia-brighter);
$gradient-blue: linear-gradient(360deg, rgb(47, 170, 255) 0%, rgba(3, 169, 231, 1) 42%, rgba(3, 169, 231, 1) 100%);
//#endregion

/*#region forms*/

$color-form-bg: $color-white;
$color-form-border: $color-grey-4;
$color-form-border-active: $color-grey-2;
$color-form-label: $color-text;
$color-form-text: $color-text;
$color-form-placeholder: $color-grey-3;
$color-form-disabled: $color-grey-3;
$color-form-valid: transparent;
$color-form-invalid: $color-invalid;
/*#endregion*/

//#region Button colors (following Prime Ng Nova theme)

$color-button-primary: $f24-blue;
$color-button-primary-hover: color.adjust($f24-blue, $lightness: -4%);

// Color for when we need to be WCAG compliant
$color-button-primary-wcag: #1276ce;

$button-warning-color: $color-casablanca;

// Prime Ng doesn't have any variable for these button's colors
$button-secondary-color: #64748b;
$button-success-color: #22c55e;
$button-danger-color: $color-danger;

//#region

$background-listitem-highlight: $f24-graphic-color-6;

// Define custom CSS color properties that can always be accessed
:root {
  --color-brand: #{$color-brand};
  --color-brand-brighter: #{$color-brand-brighter};
  --color-valid: #{$color-green};
  --color-invalid: #{$color-valencia};
  --color-delete: #{$color-valencia};
  --color-danger: #{$color-valencia};
  --color-danger-hover: #{$color-valencia-brighter};
  --color-critical: #{$color-valencia};
  --color-text: #{$color-grey-2};
  --color-delete: #{$color-valencia};
  --color-critical: #{$color-critical};
  --color-yellow: #{$color-yellow};
  --color-white: #{$color-white};

  --color-button-primary: #{$color-button-primary};
  --color-button-primary-wcag: #{$color-button-primary-wcag};
  --color-button-primary-hover: #{$color-button-primary-hover};

  --color-border: #{$color-border};
  --bi-grey-surface: #{$color-gainsboro};
  --color-grey-1: #{$color-grey-1};
  --color-grey-2: #{$color-grey-2};
  --color-grey-3: #{$color-grey-3};
  --color-grey-4: #{$color-grey-4};
  --color-grey-5: #{$color-grey-5};
  --color-grey-6: #{$color-grey-6};
}
