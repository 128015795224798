@use "shared/styles/prime-ng";
@use "shared/styles/typography";
@use "shared/styles/layout-classes";
@use "shared/styles/dimension-classes";
@use "shared/styles/color-classes";
@use "shared/styles/component-classes";
@use "style-classes";

@layer subAppGeneral;
body,
html {
  height: 100%;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

// This class will hide the visual announcer element that is used by screen readers.
.cdk-visually-hidden {
  visibility: hidden;
}

@layer subAppGeneral {
  p {
    margin: 0;
  }
}
