@use "mixins/media-query" as mq;
@use "functions" as fn;
@use "font-variables" as fontVars;

//
// ----------
// Headings
//
// ----------

$h1-mobile: 25;
$h2-mobile: 18;

$h1-desktop-lg: 30;
$h1-desktop: 25;
$h2-desktop: 20;

$h3-size: 16;

html,
body {
  font-size: fn.rem(fontVars.$base-font-size);
  font-family: fontVars.$font-primary;
}

h1 {
  font-size: fn.em($h1-mobile);
  font-weight: fontVars.$font-weight-semi-bold;
  margin-top: 0; // clearing browser default

  &.h1-lg {
    font-size: fn.em($h1-desktop-lg);
  }
}

h2 {
  font-size: fn.em($h2-mobile);
  font-weight: fontVars.$font-weight-semi-bold;
}

h3 {
  font-weight: fontVars.$font-weight-semi-bold;
  font-size: fn.em($h3-size);
}

@include mq.bi-mq(mq.$bp-screen-sm) {
  h2 {
    font-size: fn.em($h2-desktop);
  }
}

.help-text {
  font-size: fn.em(fontVars.$font-size-help-text) !important;
  font-weight: fontVars.$font-weight-semi-bold;
}

.input-label {
  display: block;
  font-size: fn.em(18);
  font-weight: fontVars.$font-weight-semi-bold;
  margin-bottom: 0.5em;
}
