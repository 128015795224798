﻿@use "./colors" as colors;

.field-error,
.dangerous-action {
  color: colors.$color-error;

  &:hover {
    color: colors.$color-error-hover;
  }
}
