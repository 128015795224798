@use "_size-variables.scss" as sizeVars;
@use "helpers/media-query" as mq;

.center-horizontal {
  margin-left: 50%;
  transform: translateX(-50%);
}

.two-buttons-container {
  max-width: em(548);
  margin-top: 1em;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  display: flex;

  > .p-button {
    width: 60%;
    max-width: em(192);
    padding-top: em(12);
    padding-bottom: em(12);

    &.back-button {
      display: none;

      @include mq.bi-mq(mq.$bp-screen-md) {
        display: inline-block;
        margin-right: auto;
      }
    }
  }

  > div.spacer {
    max-width: 0;
    margin: 0.5em;

    @include mq.bi-mq(mq.$bp-screen-sm) {
      display: inline-block;
    }
  }
}

.en-dialog-content-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .wrapper {
    max-width: sizeVars.$dialog-content-wrapper-max-width;
    width: 100%;
  }
}
