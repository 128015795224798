@use "functions" as fn;
@use "font-variables" as fontVars;
@use "colors";
@use "global-colors" as globalColors;
@use "size-variables" as sizes;
@use "mixins/media-query" as mq;
@use "prime-ng/buttons";

@layer primeng {
  body {
    .p-button {
      > .p-button-label {
        font-weight: fontVars.$font-weight-semi-bold;
      }

      &:disabled {
        background: globalColors.$color-disabled;
        color: white;
        border: none;
      }

      &.p-button-warning {
        background: colors.$color-button-danger;
        border-color: colors.$color-button-danger;
        color: white;

        &:hover {
          background: colors.$color-button-danger-hover;
        }
      }

      &.p-button-text {
        color: colors.$color-text-icon-button;

        &:hover {
          color: colors.$color-text-icon-button-hover;
        }

        &.close-icon-button {
          margin-left: auto;
          position: absolute;
          top: 0.3rem;
          right: 0.3rem;
        }

        .p-button-icon.pi-times,
        .p-button-icon.pi-chevron-left {
          font-size: fn.em(20);
          font-weight: bold;
        }
      }

      &.p-button-link.p-button-dark-text {
        color: var(--color-text);
        font-size: 14px;
        text-decoration: underline;

        &:hover {
          color: var(--color-text);
        }
      }
    }

    .p-selectbutton {
      width: 100%;
      white-space: nowrap;

      @include mq.bi-mq(mq.$bp-screen-sm) {
        width: auto !important;
      }

      .p-button {
        flex: 1 1 0%;
        text-align: center;
        display: inline-block;

        @include mq.bi-mq(mq.$bp-screen-sm) {
          flex: 0 1 auto;
        }

        &.p-highlight {
          background: var(--color-button-primary);
          border-color: colors.$color-border-main;

          &:hover {
            background: var(--color-button-primary-hover);
            border-color: colors.$color-border-main;
          }
        }
      }
    }

    .p-buttonset .p-button {
      padding: 0.6em 0.2em;

      @include mq.bi-mq(mq.$bp-screen-sm) {
        min-width: 20%;
        padding: 0.6em 2em;
        /*15% just looks best*/
      }
    }

    .p-checkbox .p-checkbox-box {
      border-color: colors.$color-interactions;

      &.p-highlight {
        border-color: colors.$color-interactions;
        background: colors.$color-interactions;
      }
    }

    .p-sidebar-content {
      height: 100%;
    }

    // Icon styling
    .pi {
      &.pi-chevron-left,
      &.pi-bars,
      &.pi-times {
        color: black;
      }

      &.pi-chevron-left--back-button {
        font-size: fn.em(20);
        font-weight: bold;
      }
    }

    // Modify the p-steps component design
    .p-steps.p-component {
      width: fn.rem(135) !important;

      @include mq.bi-mq(mq.$bp-screen-sm) {
        width: fn.rem(183) !important;
      }

      // Hide the text in "StepsComponent"'s titles.
      .p-steps-title {
        visibility: hidden;
      }

      .p-steps-item.p-highlight .p-steps-number {
        background: globalColors.$color-brand !important;
        border: globalColors.$color-brand !important;
        user-select: none;
        cursor: default;
      }
    }

    .p-float-label .p-inputwrapper-filled ~ label,
    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label input.p-filled ~ label,
    .p-float-label input:focus ~ label,
    .p-float-label textarea.p-filled ~ label,
    .p-float-label textarea:focus ~ label {
      top: -0.4rem;
    }

    .p-inputtext {
      font-family: fontVars.$font-primary;
      border: 1.5px solid colors.$color-border-main;
      border-radius: sizes.$border-radius-general;
    }

    .p-inputgroup.ng-invalid .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: 0 0 0 0.2rem colors.$color-error-hover !important;
      border-color: colors.$color-error !important;
    }

    //////////

    .p-autocomplete-panel {
      border-radius: 0 !important;
    }

    .p-autocomplete-item {
      text-align: left;
    }

    .p-accordion .p-accordion-content {
      font-size: 14px;
    }

    .p-dialog.limited-width-dialog {
      width: 90vw;
      max-width: fn.rem(600);
    }

    .p-dialog.normal-width {
      width: 80% !important;
      height: auto;
      bottom: 2%;
      margin: 4% 0;
    }

    .p-dialog,
    .p-dialog-content {
      border-radius: fn.em(10) !important;
    }

    .p-dialog-mask.p-component-overlay {
      background-color: #00425c85 !important;
    }
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    background: globalColors.$color-brand !important;
  }
}

@layer primeng.extended {
  .p-inputtext {
    font-family: fontVars.$font-primary;
    border: 1.5px solid colors.$color-border-main;
  }
}
