@use "global-colors" as colors;
@use "mixins/media-query" as mq;
@use "functions" as fn;
@use "font-variables" as fontVars;

@mixin hoverFocusStyle($textColor, $shadowColor) {
  &:focus,
  &:enabled:focus,
  &:enabled:hover,
  &:hover {
    transition: 200ms all ease;
    @if $textColor {
      color: $textColor !important;
    }
    box-shadow:
      0 0 0 fn.rem(2) white,
      0 0 0 fn.rem(4) $shadowColor,
      0 fn.rem(1) fn.rem(2) #000 !important;

    > .p-button-label {
      text-decoration: none !important;
    }
  }
}

@mixin buttonLinkStyle() {
  color: var(--color-button-primary) !important;
  font-weight: fontVars.$font-weight-common;
  padding: 0;
  line-height: normal;
  @include hoverFocusStyle(var(--color-button-primary), var(--color-button-primary));
}

button,
a {
  &.p-button:not(.p-button-outlined, .p-button-text, .p-button-link, .bi-dialog-close-button, .p-dialog-header-close, .bi-excel-button, div) {
    background: var(--color-button-primary) !important;
    border: 1px solid var(--color-button-primary) !important;

    &:not(:disabled, .p-button-danger, .p-button-secondary, .p-button-success, .facebook-btn, .twitter-btn, .primary-wcag) {
      @include hoverFocusStyle(null, var(--color-button-primary));
    }

    &.p-button-danger {
      background: colors.$button-danger-color !important;
      border-color: colors.$button-danger-color !important;
      @include hoverFocusStyle(null, colors.$button-danger-color);
    }

    &.p-button-secondary {
      background: colors.$button-secondary-color !important;
      border-color: colors.$button-secondary-color !important;
      @include hoverFocusStyle(null, colors.$button-secondary-color);
    }

    &.p-button-success {
      background: colors.$button-success-color !important;
      border-color: colors.$button-success-color !important;
      @include hoverFocusStyle(null, colors.$button-success-color);
    }

    &.facebook-btn {
      background: colors.$color-facebook !important;
      border-color: colors.$color-facebook !important;
      @include hoverFocusStyle(null, colors.$color-facebook);
    }

    &.twitter-btn {
      background: colors.$color-twitter !important;
      border-color: colors.$color-twitter !important;
      @include hoverFocusStyle(null, colors.$color-twitter);
    }

    &.primary-wcag {
      background: var(--color-button-primary-wcag) !important;
      border-color: var(--color-button-primary-wcag) !important;
      @include hoverFocusStyle(null, var(--color-button-primary-wcag));
    }

    // Make sure to always hide the button label when class "p-button-icon-only" is added to button!
    &.p-button-icon-only > .p-button-label {
      display: none;
    }

    // Auto with buttons that start out 100% wide but then just gets auto sized to be responsive
    &.bi-auto-width {
      width: 100%;

      @include mq.bi-mq(mq.$bp-screen-xxs) {
        width: auto;
      }
    }

    > a.p-button-text {
      padding: 0.2em 0.3em;
      padding: 0.2em 0.3em;
    }
  }

  // Outlined buttons
  &.p-button.p-button-outlined {
    border-color: var(--color-button-primary) !important;
    color: var(--color-button-primary) !important;

    @include hoverFocusStyle(null, var(--color-button-primary-hover));
  }

  &.p-button-text.p-button-rounded:focus:active {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #8dcdff;
  }

  // Link buttons
  &.p-button.p-button-link {
    @include buttonLinkStyle();

    &.p-button-link-danger {
      color: colors.$color-danger !important;
      @include hoverFocusStyle(colors.$color-danger, colors.$color-danger);
    }

    &.primary-wcag {
      color: var(--color-button-primary-wcag) !important;
      @include hoverFocusStyle(var(--color-button-primary-wcag), var(--color-button-primary-wcag));
    }
  }
}

// Excel export buttons
.p-button.bi-excel-button {
  background-color: colors.$color-excel !important;
  border-color: colors.$color-excel !important;

  @include hoverFocusStyle(null, colors.$color-excel);
}

.p-selectbutton > .p-button {
  background: colors.$color-button-no-highlight;
  border: 1px solid colors.$color-button-no-highlight;

  &:focus,
  &:hover {
    box-shadow:
      inset 0 0 0 fn.rem(2) white,
      inset 0 0 0 fn.rem(2) colors.$color-button-no-highlight;
  }

  &.p-highlight {
    background: var(--color-button-primary) !important;
    border: 1px solid var(--color-button-primary) !important;

    &:focus {
      box-shadow:
        inset 0 0 0 fn.rem(2) white,
        inset 0 0 0 fn.rem(2) var(--color-button-primary) !important;
    }
  }
}
