@use "global-colors" as gColors;

$color-interactions: gColors.$color-brand;
$color-interactions-hover: gColors.$color-brand-brighter;
$color-background-dropdowns: #d3f2ff;
$color-overlay: #00425c;
$color-interactive-buttons: #d6d6d6;
$color-text: #262626;
$color-main-background: #ffffff;
$color-error: #e53d00;
$color-error-hover: #cc3701;
$color-interactive-inputs: #afafaf;

// ============ Button colors ===============
$color-button-primary: $color-interactions;
$color-button-primary-hover: $color-interactions-hover;
$color-button-secondary-border-and-text: $color-interactions;
$color-button-secondary-border-and-text-hover: $color-text;
$color-disabled: $color-interactive-buttons;
$color-button-danger: $color-error;
$color-button-danger-hover: $color-error-hover;

$color-text-icon-button: $color-interactions;
$color-text-icon-button-hover: $color-interactions-hover;

// ==============================

$color-border-main: $color-interactions;
$color-border-disabled: $color-interactions;

$color-icons-blue: $color-interactions;
