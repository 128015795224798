$base-font-size: 16; // 16px = 12pt as required by WCAG
$font-size-inputs: 0.875rem;
$font-size-buttons: 16;
$font-size-help-text: 14;

$font-primary: Inter, Tahoma, Helvetica, Arial, sans-serif;
$font-secondary: "Times New Roman", Times, Baskerville, Georgia, serif;

$font-weight-semi-bold: 500;
$font-weight-common: 700;
