/*
===========================================================================================================================
============ Classes used for parent elements that make up small component (so NOT to be confused with Angular component) =
===========================================================================================================================
*/

@use "_colors.scss" as colors;
@use "functions" as fn;
@use "size-variables" as sizes;

.en-blue-box {
  display: block;
  background-color: colors.$color-background-dropdowns;
  border-radius: sizes.$border-radius-general;
  padding: 0.5em;

  &--grey {
    background-color: colors.$color-disabled;
  }
}
