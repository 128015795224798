﻿// Mobile first media query.
// param "from-breakpoint" must be in pixels
@mixin bi-mq($from-breakpoint) {
  @media screen and (min-width: $from-breakpoint) {
    @content;
  }
}

// breakpoint variables to be used as variables in media queries
$bp-screen-xxs: 480px;
$bp-screen-xs: 568px;
$bp-screen-sm: 768px;
$bp-screen-sm-primeFlex: 576px;
$bp-screen-md-primeFlex: 768px;
$bp-screen-lg-primeFlex: 992px;
$bp-screen-xl-primeFlex: 1200px;
$bp-screen-sm-plus: 950px;
$bp-screen-md: 1024px;
$bp-screen-lg: 1150px;
$bp-screen-xl: 1280px;
$bp-screen-xxl: 1440px;
