/************ A mixed set of classes for different shared styles ***************/
/*_____________________________________________________________________________*/

.row-hover {
  transition: background 150ms ease-in-out;

  &:hover {
    background: var(--surface-border);
  }
}

// Vertically centering absolute/fixed positioned elements
.vertical-center-absolute {
  top: 50%;
  transform: translateY(-50%);
}

// Hide the scrollbar of elements with class `hide-scrollBars` and keep scroll functionality
.hide-scrollBars::-webkit-scrollbar {
  display: none;
}
