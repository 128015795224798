@use "functions.scss" as fn;

.button-lg,
.button-md,
.input-lg {
  min-height: fn.em(45);
}

.button-md {
  padding: 0.6em 2em !important;
}

.button-lg {
  padding: 0.6em 3em !important;
}

.button-max-160 {
  max-width: 160px;
}
